@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,600,600i,700,700i,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Poppins', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  font-family: Roboto;
}

h1,
h2,
h3,
h4,
h5.h6 {
  font-family: Roboto;
}

a {
  text-decoration: none;
  color: unset;
}

::-webkit-scrollbar{
  width: 0px;
}

:focus,
:active {
  outline: none !important;
  box-shadow: unset !important;
}
.paddingTop {
  padding-top: 30px ;
  display: flex;
}

